.dashboard-background {
  background-color: #f8f7f6;
  min-height: 100vh;
  height: 100%;
}

.preloader-wrapper {
  width: 55%;
  padding: 50px 5%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.025);
  margin-bottom: 50px;
  margin-top: 50px;
}

.topbar {
  width: 100%;
  height: 50px;
  background-color: #139deb;
  display: flex;
}

.toolbar-admin-menu {
  color: #fff !important;
  align-self: center !important;
  margin-left: 30px;
}

.toolbar-right {
  margin-left: auto;
}

.toolbar-admin-menu-icon {
  font-size: 16px;
  margin-left: 20px;
}

.topbar-avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.topbar-logo {
  width: 80px;
  cursor: pointer;
  margin-left: 50px;
  margin-top: 10px;
}

.topbar-user-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  margin-right: 100px;
  margin-top: 10px;
  cursor: pointer;
}

.topbar-user {
  font-size: 19px;
  color: #139deb !important;
  margin-left: 5px;
  margin-top: 3px;
}

.topbar-logout {
  display: inherit !important;
  color: #ffffff !important;
  font-size: 16px;
  margin-top: -24px;
  margin-left: -10px;
  cursor: pointer;
}

.weekcard {
  width: 55%;
  padding: 70px 5%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 100px;
  box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.03);
}

.weekcard-titile {
  font-size: 24px !important;
}

.weekcard-subtitile {
  font-size: 20px !important;
}

.weekcard-titile,
.weekcard-subtitile {
  text-align: center;
  font-weight: 300 !important;
}

.weekcard-button {
  width: 100px;
  height: 40px;
  display: block !important;
  margin: 0 auto !important;
  background-color: #139deb !important;
  border-color: #139deb !important;
  border-radius: 5px !important;
}

.weekcard-slider {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.projects {
  margin-top: 20px;
  margin-bottom: 30px;
}

.project-selectors {
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
}

.project-item {
  align-self: center;
  margin-right: 10px;
  color: #242424 !important;
}

.project-item-fixed-width {
  flex-basis: 130px;
  overflow: hidden;
}

.project-select {
  margin-left: 90px !important;
  width: 160px;
}

.project-additional {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 30px !important;
}

.profile-select,
.profile-info,
.tutorial,
.project-controller {
  width: 55%;
  padding: 50px 5%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.025);
  margin-bottom: 50px;
  margin-top: 50px;
}

.profile-fio-wrapper {
  display: flex;
}

.profile-avatar {
  width: 40px;
  height: 40px;
  background: #139deb;
  color: #ffffff;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-avatar-icon {
  font-size: 22px;
  display: inline-block;
  margin-left: 9px;
  margin-top: 8px;
}

.profile-avatar-img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile-avatar-label:hover {
  position: absolute;
  opacity: 0.8;
}

.profile-avatar-label {
  width: 40px;
  height: 40px;
  font-size: 20px;
  border-radius: 50%;
  padding-left: 10px;
  padding-top: 3px;
  background: #242424;
  position: absolute;
  opacity: 0;
}

.profile-avatar-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.profile-avatar-input:hover {
  opacity: 1;
}

.profile-header {
  display: block;
  font-size: 20px;
  line-height: 20px;
  margin-top: 2px;
  color: #242424 !important;
}

.profile-subheader {
  display: block;
  font-size: 14px;
  color: #242424 !important;
}

.profile-buttons {
  display: flex;
  margin-top: 30px;
  box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.01);
}

.profile-button {
  width: 50%;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
  color: #242424;
  border: 1px solid #139deb;
  padding: 5px 0;
  white-space: nowrap;
}
.profile-button:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.profile-button:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.profile-button-active {
  background-color: #139deb !important;
  color: #f8f7f6 !important;
}

.profile-button-icon {
  margin-right: 5px;
}

.profile-infotext span {
  color: #242424;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.profile-infotext span:nth-child(2n) {
  margin-bottom: 25px;
}

.profile-text-bold {
  font-weight: bold;
}

.profile-history {
  width: 55%;
  padding: 30px 5%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.025);
  margin-bottom: 50px;
  margin-top: 30px;
}

.profile-projects {
  width: 55%;
  padding: 30px 5%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.025);
  margin-bottom: 50px;
  margin-top: 30px;
}

.profile-history-project {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.profile-history span {
  color: #242424;
  margin-right: 10px;
}

.profile-history-project-text {
  display: block;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
}

.profile-history-stage {
  margin-left: 30px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
}

.profile-history-value {
  justify-self: flex-end;
  flex-grow: 1;
  text-align: end;
  width: 20px;
  white-space: nowrap;
}

.tutorial-header {
  color: #242424 !important;
  text-align: center;
}

.profile-edit {
  width: 60%;
  margin: 0 auto !important;
}

.tutorial-text {
  color: #242424 !important;
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (max-width: 900px) {
  .profile-history,
  .profile-info,
  .profile-select,
  .weekcard {
    width: 70%;
  }

  .project-select {
    margin-left: 50px !important;
    width: 160px;
  }

  .profile-history-project-text {
    width: 200px;
  }

  .profile-history-stage {
    width: 200px;
    margin-left: 40px;
    white-space: nowrap;
  }
}

@media (max-width: 600px) {
  .profile-history,
  .profile-info,
  .profile-select,
  .weekcard {
    width: 95%;
  }

  .profile-history-project-text {
    width: 170px;
    white-space: nowrap;
  }

  .profile-history-stage {
    width: 170px;
    margin-left: 30px;
    white-space: nowrap;
  }

  .project-select {
    margin-left: 30px !important;
    width: 140px;
  }
}

@media (max-width: 400px) {
  .profile-history,
  .profile-info,
  .profile-select,
  .weekcard {
    width: 100%;
  }

  .profile-history-project-text {
    white-space: nowrap;
  }

  .profile-history-stage {
    margin-left: 10px;
    white-space: nowrap;
  }

  .project-select {
    margin-left: 10px !important;
    width: 100px;
  }
}

.ant-slider-step {
  display: none !important;
}
