.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.login-form {
  width: 300px;
  flex-grow: 0;
  margin: 0 auto;
}

.login-form-forgot {
  float: right;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
}

.site-form-item-icon {
  margin-right: 4px;
  color: #bfbfbf;
}

.login-password,
.login-reset-link {
  margin-bottom: 15px;
}

.login-logo {
  width: 116px;
  height: 35px;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  object-fit: cover;
  margin-bottom: 40px;
}

.login-register {
  float: left;
}
.login-text-blue {
  color: #00aef0;
}

.login-button-blue {
  background-color: #00aef0;
  border-color: #00aef0;
}

.ant-input-group-addon {
  background-color: #ffffff !important;
}

.forgot-title {
  text-align: center;
  margin-top: 0;
  margin-bottom: 12px;
}

.forgot-title-text {
  color: #2b2c30 !important;
}

.auth-error {
  text-align: center;
  color: #ff4d4f !important;
}
