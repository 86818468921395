.project-controller {
  width: 600px;
}

.project-users-search,
.project-add-stages {
  width: 100%;
}

.project-add-title {
  text-align: center;
  display: block !important;
  margin-bottom: 30px !important;
  line-height: inherit;
}

.project-form-desc {
  line-height: 14px !important;
}

.project-stage {
  display: flex;
}

.project-stage-input {
  width: 90% !important;
}

.project-stage-remove {
  width: 10% !important;
}

.project-participant {
  display: flex;
  align-items: center;
  margin-top: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  padding: 10px 25px;
  box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.01);
}

.project-participant-image {
  background-color: #00aef0;
  border-color: #00aef0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.project-participant * {
  margin-right: 30px;
}

.project-participant-no-margin {
  margin-bottom: 0;
}

.project-participant:last-child {
  justify-self: flex-end;
  margin-bottom: 30px;
}

.project-participant-remove {
  margin-left: auto;
  margin-right: -15px;
}

.project-participant-icon {
  color: #fff !important;
  font-size: 18px;
  text-align: center;
  margin-left: 5.5px;
  margin-top: 4.5px;
}

.project-add-button {
  display: block !important;
  margin: 0 auto;
  margin-top: 20px;
  background: #00aef0 !important;
  border: none !important;
}

.project-add-error {
  margin-top: 15px;
  text-align: center;
  color: #ff4d4f !important;
}

.project-overview {
  width: 55%;
  padding: 50px 5%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.025);
  margin-top: 50px;
}

.project-overview {
  width: 55%;
  padding: 50px 5%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.025);
  margin-top: 50px;
}

.projects-wrapper::after {
  content: "";
  overflow: hidden;
  display: block;
}

.project-overview:last-child {
  margin-bottom: 50px;
}

.project-overview > span,
.project-overview > h4 {
  display: block;
  text-align: center;
}

.project-overview > span {
  margin-top: 20px;
  margin-bottom: 15px !important;
}

.project-overview-fio {
  color: #000 !important;
}

.project-overview-info {
  display: flex;
  justify-content: center;
}
.project-overview-info > span {
  color: #000 !important;
}

.project-overview-info :first-child {
  margin-right: 40px;
}

.project-overview-info {
  display: flex;
  justify-content: center;
}

.project-overview-button {
  display: block !important;
  margin: 0 auto;
  color: #139deb !important;
  border-color: #139deb !important;
  margin-top: 40px;
}

.projects-link {
  color: #139deb !important;
}

.projects-overview-divider {
  margin-top: 35px !important;
}

.project-edit-button {
  margin-top: 50px;
  margin-bottom: 10px;
}

.project-add-edit-confirm {
  margin-top: 30px;
}

.export,
.accept {
  width: 55%;
  padding: 50px 5%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.025);
  margin-top: 50px;
}
